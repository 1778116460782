import React, {useContext, useState} from 'react'
import { useHistory } from 'react-router'
import LinkMenu from '../../components/Menu/LinkMenu'
import ContextAuthLiders from '../../context/authContext/ContextAuthLiders'
import logo from '../../img/logo.png'
import { THEME } from '../../theme'

export default React.memo(() => {
	const {stateData} = useContext(ContextAuthLiders)
    const {dispatchData} = useContext(ContextAuthLiders)
    let role = null
	if (localStorage.getItem('role')) {
		role = localStorage.getItem('role')
	} else {
		 role = stateData.role
	}

	const BackToLogin = () => {
		if (window.confirm('Ви впевнені?')) {
			localStorage.removeItem('role')
			localStorage.removeItem('token')
			dispatchData({
				type: 'BACK_TO_LOGIN'
			})
		}	 
	}
      const [activeCategory, setActiveCategory] = useState(0)

	  const menu = [
		{
			id:0,
			title: 'Портфоліо',
			link: '/portfolio'
		},
		
	]

	const classActive = {
		color: THEME.MAIN_COLOR,
		background: THEME.SUB_COLOR,
		transition: THEME.TRANSITION
	}
	  
	return (
			<>
				<div style={{borderBottom: '1px solid #fff', marginBottom: '15px'}}>
					<img style={{width: '90%', padding: '5px'}} src={logo} alt="" />
				</div>
				<div>
					{menu.map((elem, idx) => {
						return (
                           <LinkMenu onClick={() => setActiveCategory(idx)}
						    key={`${elem.id + idx}`} 
							style={activeCategory === elem.id ? 
							classActive : {}} 
							to={elem.link}>
								{elem.title}
							</LinkMenu>
						)
					})}
					<LinkMenu onClick={BackToLogin} to="/">Вихід</LinkMenu>
				</div>
			</>
		)
})