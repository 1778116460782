

const admins = [
    { 
        club_id: 1,
        login: 'Maxim',
        password: 'AngelWing',
        name: 'Max',
        role: 'admin'
    },
    {
        id: 6137,
        club_id: 26,
        login: 'Ruslan',
        password: 'ch2022',
        name: 'Ruslan',
        role: 'lider'
    },
    {
        club_id: 3,
        login: 'Vasya',
        password: '1234',
        name: 'Vasya',
        role: 'lider'
    }
]

export default admins