import React, {
    useState, useEffect
} from 'react'
import { getData } from './../../../project/utils'

const GridFilter = ({
    activeFilter,
    setActiveFilter,
    classes,
    routeFilter,
    net,
    staticData = false,
    options = [],
    dataItem,
    setDataItem
}) => {
    const [filterParams, setFilterParams] = useState([])

    useEffect(() => {
        staticData ? 
            setFilterParams(options) :
        (async () => {
            const data = await getData(`${net.BACK_URL}/${routeFilter}`)
            setFilterParams(data)
            
             console.log(data)
        })()
    }, [])
    return (
        <select className={classes.gridFilter} value={activeFilter} onChange={(e) => {
            setActiveFilter(e.target.value)
            
            setDataItem({
                ...dataItem,
                courses_id: e.target.value
            })     
        }}>
            
            {filterParams && filterParams.map((el, idx) => (
                <option value={el.id} key={`opti${idx}`}>{el.title}</option>
                
            ))}
        </select>
    )
}

export default GridFilter