export const getData = async(
    url,
    method
) => {
    const res = await fetch(url, {
        method: method || 'GET',
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
    }).then((response) => {
        if (response.status === 200) {
           
        }
        return response
    })
    return await res.json()
}

export const getGridHandlers = (updateHandler, qrHandler, deleteHandler) => {
    return {
        buttons: { 
            update: (id) => updateHandler(id),
            // qr: (id) => qrHandler(id),
            delete: (id) => deleteHandler(id),
        }
    }
}



export const getPortfolioFields = (dataItem) => {
    const fields = [
        {
            attribute: 'title',
            type: 'input',
            title: 'Назва ',
            placeholder: 'Введіть назву'
        },
        {
            attribute: 'date',
            type: 'date',
            title: 'Дата',
            placeholder: 'опис продукту '
        },
        {
            attribute: 'price',
            type: 'input',
            title: 'Ціна ',
            placeholder: 'Введіть ціну'
        },
        {
            attribute: 'square',
            type: 'input',
            title: 'Опис',
            placeholder: 'Введіть ціну'
        },
        {
            attribute: 'image',
            type: 'image',
            title: 'Зображення',
            placeholder: 'Оберіть зображення'
        },
        {
            attribute: 'description',
            type: 'article',
            title: 'Описання',
            placeholder: 'Опис',
            value: dataItem['description']
        },
        {
            attribute: 'small_description',
            type: 'article',
            title: 'Малий опис',
            placeholder: 'Опис',
            value: dataItem['small_description']
        },
       
    ]
    return {
        fields
    }
}

export const getFields = () => {
    const fields = [
        {
            attribute: 'title',
            type: 'input',
            title: 'Назва',
            placeholder: 'Введіть ПІБ'
        }
    ]
    return {
        fields
    }
}
