import React from 'react';
import './App.css';
import {Switch, Route} from 'react-router-dom';

import Auth from './routes/auth/Auth';
import AuthExternal from './routes/AuthExternal';
import ReducerAuthLiders from './context/authContext/ReducerAuthLiders';
import StateAuthLiders from './context/authContext/StateAuthLiders';
import ContextAuthLiders from './context/authContext/ContextAuthLiders';

import Portfolio from './routes/Portfolio';





function App() {

  const [stateData, dispatchData] = React.useReducer(ReducerAuthLiders, StateAuthLiders)
  return (
          <ContextAuthLiders.Provider value={{stateData, dispatchData}}>
            <Switch>    
              <Route path="/" exact component={Auth} />
              <AuthExternal path='/portfolio'  component={Portfolio} />      
            </Switch>
          </ContextAuthLiders.Provider>  
  );
}

export default App;
