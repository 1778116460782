import React, {
    useState, useMemo
} from 'react'
import Editor from '../../widgets/editor/Editor'
import Modal from '../../widgets/modal/Modal'
import {NET} from '../../network';
import { getPortfolioFields } from '../../project/utils';

const UpdatePortfolioModal = ({
    showModalUpdate,
    setShowModalUpdate,
    dataItem,
    setDataItem,
    afterSuccess,
    createModalConfig,
    role
}) => {
    const fields = useMemo(() => getPortfolioFields(dataItem), [showModalUpdate, setShowModalUpdate, dataItem])
    
    // const afterSuccess = () => {
    //     console.log('Success')
    // }

    return (
        <Modal
            showModal={showModalUpdate}
            setShowModal={setShowModalUpdate}
            title={createModalConfig.title}
        >
            <Editor 
                fields={fields.fields}
                dataItem={dataItem}
                setDataItem={setDataItem}
                route={'portfolio'}
                afterSuccess={afterSuccess}
                net={NET}
                createModalConfig={createModalConfig}
            />
        </Modal>
    )
}
export default UpdatePortfolioModal