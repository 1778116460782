import React, {
    useEffect,
    useState
} from 'react'

const ColorText = ({
    value,
    name,
    width,
    styles,
    customStyles
}) => {
  
   const [val, setVal] = useState(false)
   const [color, setColor] = useState('transparent')

   useEffect(() => {
    if (name === 'order_status') {
        if (value === 'success') {
            setVal('Відправлено')
            setColor('green')
        } else if (value === 'open') {
            setVal('Відкрито')
            setColor('yellow')
        } else if (value === 'cancel') {
            setVal('Відмінено')
            setColor('red')
        }
    }
   }, [value])
    
    return (
        <div style={{
            width: width,
            'min-width': width ,
            backgroundColor: color
        }} className={[styles.gridRowItem2, customStyles?.gridRowItem].join(' ')}>
            <div>{val}</div>
        </div>
    )
}
export default ColorText