import React, {useState, useContext} from 'react'
import { Redirect } from 'react-router-dom'
import styles from './auth.module.scss'
import DATA from './../../userData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {  faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import ContextAuthLiders from '../../context/authContext/ContextAuthLiders'


const Auth = () => {
    const {dispatchData} = useContext(ContextAuthLiders)
    const [visiblePassword, setVisiblePassword] = useState(false)
    const Icon = 
        <FontAwesomeIcon 
        icon={visiblePassword ? faEyeSlash : faEye } 
        onClick={() => setVisiblePassword(!visiblePassword) }
        />
    
    const InputType = visiblePassword ? "text" : "password"
    
    const [value, setValue] = useState(
        {
        login: '',
        password: '',
        name: ''
        })

    const [route, setRoute] = useState(false)
     
    const signIn = () => {
        const [user] = DATA.filter(elem => value.login === elem.login)
        if (user === undefined) {
            alert('Невірний логін')
        } else {
            if (user.password === value.password) {
                localStorage.setItem('token', 'Msport')
                localStorage.setItem('role', user.role)
                localStorage.setItem('club_id', user.club_id)

                dispatchData({
                    type: 'AUTH_LIDERS',
                    payload: user
                })
                setRoute(true)
            } else {
                alert('Пароль невірний')
            }
        }
   }

    return (
        <>
        <form className={styles.form}>
            <input 
                value={value.login} 
                onChange={(e) => setValue({
                    ...value, 
                    login: e.target.value
                    })} 
                    className={styles.form__input}  
                    placeholder="Введіть логін" 
                    />
                <div className={styles.form__password}>
                <input 
                    value={value.password} 
                    onChange={(e) => setValue({
                        ...value,
                        password: e.target.value
                    })} 
                    className={styles.form__input} 
                    type={InputType}  
                    placeholder="Введіть пароль" 
                />
                    <span className={styles.form__icon}>{Icon}</span>
                </div>
            {!route ? <button className={styles.form__btn} onClick={signIn} >
               Увійти
            </button> : <Redirect to="/portfolio" />}
        </form>
        </>
    )
}

export default Auth