
import React from 'react';
import styles from './preloader.module.scss'

const Preloader = () => {
    return (
        <div className={styles.loader}>
            <div className={styles.loader__text}>Йде завантажання</div>
        <div className={styles.loader__inner}>
            <div className={styles.loader__line__wrap}>
                <div className={styles.loader__line}></div>
            </div>
            <div className={styles.loader__line__wrap}>
                <div className={styles.loader__line}></div>
            </div>
            <div className={styles.loader__line__wrap}>
                <div className={styles.loader__line}></div>
            </div>
           
            
        </div>
    </div>
    )
}

export default Preloader