import React from 'react'
import { Header } from '../modules/Header/Header'
import Menu from '../modules/Menu/Menu'
import {Route} from 'react-router-dom'
import {THEME} from '../theme'

const LayoutAdmin = ({  
    path, 
    exact = false,
    component,
    children
  }) => {
    return (
        <div style={{display: 'flex', height: '100vh', 'max-height': '100vh'}}>
        <div style={{	width: '15%',
                        backgroundColor: THEME.MAIN_COLOR, 
                        padding: '20px'}}
                        >
            <Menu />
        </div>
        <div style={{width: '85%'}}>
          <Header />
            <div style={{padding: '20px'}}>
              <Route path={path} component={component}/>
            </div>
        </div>
  </div>
    )
}

export default LayoutAdmin
