import React, {
    useEffect, useState, useMemo
} from 'react'
import GridComponent from '../widgets/grid/GridComponent'
import classes from './../project/styles/peoples-styles.module.scss'
import { NET } from '../network';
import {getData, getGridHandlers } from '../project/utils';

import Preloader from '../widgets/preloader/Preloader';
import Pagination from '../widgets/pagination/Pagination';
import { transformData } from '../views/peoples/utils';
import UpdatePortfolioModal from './components/UpdatePortfolioModal';




const Portfolio = () => {
    const [page, setPage] = useState(1)
    const [settingsPage, setSettingsPage] = useState({
        maxPage: 1,
        count: ''
    })
    const [filter, setFilter] = useState({
        page: page
    })

    const thead = [
        {
            title: 'ID',
            alias: 'id',
            width: 30
        },
        {
            title: 'Назва',
            alias: 'title',
            width: 150,
        },
        {
            title: 'Дата',
            alias: 'date',
            width: 150
    
        },
        {
            title: 'Ціна',
            alias: 'price',
            width: 150
    
        },
        {
            title: 'Опис',
            alias: 'square',
            width: 200
        },
        {
            title: 'Дії',
            alias: 'buttons',
            width: 80
        }
    ]
    const elems = [
        {
            name: 'id',
            type: 'text',
            value: 'value',
            width: 30 
        },
        {
            name: 'title',
            type: 'text',
            value: 'value',
            width: 150
        },
        {
            name: 'date',
            type: 'text',
            value: 'value',
            width: 150
        },
        {
            name: 'price',
            type: 'text',
            value: 'value',
            width: 150
        },
        {
            name: 'square',
            type: 'text',
            value: 'value',
            width: 200
        },       
        {
            name: 'buttons',
            type: 'buttons',
            value: ['delete'],
            width: 80
        }  
    ]
 
    const [showModalUpdate, setShowModalUpdate] = useState(false)
    const [showModalCreate, setShowModalCreate] = useState(false)
    const [gridData, setGridData] = useState(null)
    const [dataItem, setDataItem] = useState({
        id: '',
        title: '',
        date: '',
        price: '',
        square: '',
        description: '',
        small_description: '',
      
    })
    const [createModalConfig, setCreateModalConfig] = useState({
        title: 'Створити продукт',
        type: 'create',
        btn: 'Сиворити '
    })
    
    useEffect(() => {
        let filterParams = ''
        Object.keys(filter).map((el, idx) => {
            if (idx === 0) {
                filterParams = `?${el}=${filter[el]}`
            } else {
                filterParams = `${filterParams}&${el}=${filter[el]}`
            }
        })
		const fetchData = async () => {
			try {		
				const url = `${NET.APP_URL}/portfolio${filterParams}`
            
				const response = await fetch(url, {
					method: 'GET',
					// cors: 'cors',
					headers: {
						'Content-Type': 'application/json'
				      // 'Content-Type': 'application/x-www-form-urlencoded',
				  },
				})
                if (response.status === 200) {
                    const result = await response.json()
                    
                    setGridData(transformData(thead, result.data.data))
                    console.log(result)
                    setSettingsPage({
                        ...settingsPage,
                        maxPage: result.last_page
                    })
                }
			} catch (e) {
				console.log(e)
			}	
		}
        if (!showModalUpdate) {
            fetchData()
        }
	}, [setFilter, filter, showModalUpdate, setPage, page])
  
    const updateHandler = async (id) => {
         const url = `${NET.APP_URL}/portfolio/${id}`
         const data = await getData(url)
        //  if (data) {
             const user = data.data
             console.log(user)
            setDataItem({
                id: user.id,
                title: user.title,
                date: user.date,
                price: user.price,
                square: user.square,
                description: user.description,
                small_description: user.small_description,
           })
        //  }
        setCreateModalConfig({
            title: 'Редагувати продукт',
            type: 'update',
            btn: 'Редагувати '
        })
        setShowModalUpdate(true)
    }
  
    
    
    const deleteHandler =  async (id) => {
       if (window.confirm('Ви впевнені?')) {
        const url = `${NET.APP_URL}/portfolio/${id}`
        setFilter({
            type: 'sport',
            sport_id: 'all',
            district: 'all'
        })
        const data = await getData(url, 'DELETE')
        console.log(data)
        setGridData( transformData(thead, data.data.data))
        // window.location.reload()
       } 
    }

    const gridHandlers = useMemo(() => getGridHandlers(
        updateHandler,
        false,
        deleteHandler
    ), [])

    const handleCreate = () => {
        setDataItem({
            id: '',
            title: '',
            date: '',
            price: '',
            square: '',
            description: '',
            small_description: '',
    
        })
        setCreateModalConfig({
            title: 'Створити продукт',
            type: 'create',
            btn: 'Сиворити '
        })
        setShowModalUpdate(true)
    }
    const afterSuccessCreate = () => {
        setShowModalUpdate(false)
    }
    return (
        <div>
            <div className={classes.createBtn} onClick={handleCreate}>Створити продукт</div>
            {gridData ? ( <GridComponent 
                customStyles={classes}
                gridData={gridData}
                elems={elems}
                filter={filter}
                // filterOptions={filterOptions}
                setFilter={setFilter}
                classes={classes}
                settings={{
                    //Включает порядковые номера у строк
                    counter: true,
                    net: NET,
                    //Включает блок фильтров и поиска
                    filter: false,
                    routeFilter: 'courses'
                }}
                gridHandlers={gridHandlers}
                setGridData={setGridData}
                settingsPage={settingsPage}
                setSettingsPage={setSettingsPage}
            />) : (<Preloader />)}   
            
           <UpdatePortfolioModal
               showModalUpdate={showModalUpdate}
               setShowModalUpdate={setShowModalUpdate}
               dataItem={dataItem}
               setDataItem={setDataItem}
               createModalConfig={createModalConfig}
               afterSuccess={afterSuccessCreate}
               role={"admin"}
            />
            <Pagination 
                filter={filter}
                setFilter={setFilter}
                setSettingsPage={setSettingsPage}
                settingsPage={settingsPage}
            />
        </div>
    )
}
export default Portfolio

 