import React from 'react'
import { Redirect } from 'react-router-dom'
import LayoutAdmin from '../layouts/LayoutAdmin'

const AuthExternal = ({
    path,
    component
}) => {
    
    const backtoken = 'Msport'
	let auths = localStorage.getItem('token')
	if (auths === backtoken) {
		auths = true
	} else {
		auths = false
	}
    return (
        <>
            {auths ? <LayoutAdmin path={path} component={component}/> : <Redirect to="/" />}
        </>
    )
}
export default AuthExternal