import React, { useEffect, useState } from 'react'
import styles from './pagination.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight, } from '@fortawesome/free-solid-svg-icons'

const Pagination = ({filter, setFilter, settingsPage, setSettingsPage}) => {
    
    const [visible, setVisible] = useState(filter.page !== 1)
    const [visibleNextPage, setVisibleNextPage] = useState(filter.page !== settingsPage.maxPage)
    const [activePage, setActivePage] = useState(1)
    
   

    useEffect(() => {
        if (filter.page !== 1) {
          setVisible(false)
          
        } else {
            setVisible(true)
        }
        if (filter.page !== settingsPage.maxPage) {
            setVisibleNextPage(false)
          } else {
              setVisibleNextPage(true)
          }
    }, [filter, setFilter, settingsPage, setSettingsPage])
    
    let values = [ ]
    if (settingsPage.maxPage <= 1) {
        values = []
    } else if (settingsPage.maxPage <= 2 ) {
        values = []
    } else if (settingsPage.maxPage <= 3 ) {
        values = [
            {value: 2}                
        ]
    } else if (settingsPage.maxPage <= 4 ) {
        values = [
            {value: 2}, 
            {value: 3},               
        ]
    } else if (settingsPage.maxPage <= 5 ) {
        values = [
            {value: 2}, 
            {value: 3},  
            {value: 4},              
        ]
    } else if (filter.page === 1 ) {
        values = [
            {value: filter.page + 1},
            {value: filter.page + 2}
        ]
    } else if (filter.page === 2) {
        values = [
            {value: filter.page},
            {value: filter.page + 1},
            {value: filter.page + 2}
        ]
    } else if (filter.page === 3 ) {
        values = [
            {value: filter.page - 1},
            {value: filter.page},
            {value: filter.page + 1},
            {value: filter.page + 2}
        ]
    } else if (filter.page > 3 && filter.page < settingsPage.maxPage - 2) {
        values = [
            {value: filter.page - 2},
            {value: filter.page - 1},
            {value: filter.page},
            {value: filter.page + 1},
            {value: filter.page + 2}
        ]
    }  else if (filter.page > 3 && filter.page < settingsPage.maxPage - 1) {
        values = [
            {value: filter.page - 2},
            {value: filter.page - 1},
            {value: filter.page},
            {value: filter.page + 1}
        ]
    }  else if (filter.page > 3 && filter.page < settingsPage.maxPage) {
        values = [
            {value: filter.page - 2},
            {value: filter.page - 1},
            {value: filter.page}
        ]
    } else if (filter.page === settingsPage.maxPage) {
        values = [
            {value: filter.page - 3},
            {value: filter.page - 2},
            {value: filter.page - 1},
        ]
    }
    return (
        <div className={styles.pagination}>
        <button className={visible ? styles.pagination__chevron__btnOne : styles.pagination__chevron__btn} disabled={visible}  onClick={() => {
                setFilter({
                    ...filter,
                    page: filter.page - 1
                })
            }}><FontAwesomeIcon className={styles.pagination__chevron} icon={faChevronLeft}/></button>
            <button className={filter.page === 1 ? styles.active : styles.pagination__btn} onClick={() => {
                setFilter({
                    ...filter,
                    page: 1
                })
            }}>1</button>
            {filter.page > 4 ? (<div>...</div>) : (<div></div>)}
            {values.map((elem) => {
                return (
                    <button className={filter.page === elem.value ? styles.active : styles.pagination__btn} onClick={() => {
                        setFilter({
                            ...filter,
                            page: elem.value
                        })
                        setActivePage(elem.value)
                    }}>{elem.value}</button>
                )
            })}
            {filter.page < settingsPage.maxPage - 3 ? (<div>...</div>) : (<div></div>)}
            {settingsPage.maxPage > 1 && <button className={filter.page === settingsPage.maxPage ? styles.active : styles.pagination__btn}  onClick={() => {
                setFilter({
                    ...filter,
                    page: filter.page = settingsPage.maxPage
                })
            }}>
                {settingsPage.maxPage}
            </button>}
            <button className={visibleNextPage ? styles.pagination__chevron__btnOne : styles.pagination__chevron__btn} disabled={visibleNextPage}   onClick={() => {
                setFilter({
                    ...filter,
                    page: filter.page + 1
                })
            }}><FontAwesomeIcon className={styles.pagination__chevron} icon={faChevronRight}/></button>
        </div>
    )
}

export default Pagination