export const transformData = (head, body) => {
    const header = head && head.map(el => el.alias)

    const boder = body.map((elem) => {
        let accum = {}
        header.map((headItem) => {
            Object.keys(elem).map((key) => {
                if (key === 'sports') {
                    let value = ''
                    if (elem[key] === 'true') {
                        value = 'Так'
                    } else if (elem[key] === 'false') {
                        value = 'Ні'
                    } 
                    return accum[key] = value
                }
               else if (headItem === key) {
                    accum[key] = elem[key]
                }
            })
        })
        return accum
    })
    return {
        header: head,
        boder
    }
}
export const thead = [
    {
        title: 'ID',
        alias: 'id',
        width: 50
    },
    {
        title: 'ПІБ',
        alias: 'name',
        width: 200

    },
    {
        title: 'Дата',
        alias: 'date',
        width: 100
    },
    {
        title: 'Телефон',
        alias: 'phone',
        width: 130
    },
    {
        title: 'Email',
        alias: 'email',
        width: 180
    },
    {
        title: 'Відвідує',
        alias: 'sports',
        width: 80
    },
    {
        title: 'Занять на тиждень',
        alias: 'sports_count',
        width: 80
    },
    {
        title: 'Назва Клубу',
        alias: 'club_id',
        width: 180
    },
    {
        title: 'Спорт',
        alias: 'sport_id',
        width: 160
    },
    {
        title: 'Район',
        alias: 'district',
        width: 120
    },
    {
        title: 'Адреса',
        alias: 'adress',
        width: 240
    },
    // {
    //     title: 'Дети до 16',
    //     alias: 'children'
    // },
    // {
    //     title: 'Кільк. занять',
    //     alias: 'sports_count',
    //     width: 100
    // },
    // {
    //     title: 'Спорт в детстве',
    //     alias: 'sports_child'
    // },
    {
        title: '',
        alias: 'buttons',
        width: 70
    }
]