const ReducerAuthLiders = (state, action) => {
    switch (action.type) {
        case "AUTH_LIDERS":
        return {  
            club_id: action.payload.club_id, 
            name: action.payload.name,
            role: action.payload.role
        }
        case "BACK_TO_LOGIN":
            return {  
                club_id: '', 
                name: '',
                role: ''
            }

        default:
            return state
    }
}

export default ReducerAuthLiders